
import { searchBlockPickerClose, searchBlockPickerOpen, updatetriggerSearchAfterUpdateValue, updateSearchBarOptions } from "./search-bar.js"
import { submit as dynamicSearchSubmit } from "./dynamic-search.js"
import { breakpoints, lockout } from "./utilities.js";


var activeClass = 'is-active';
var disabledAfterUncheckedAttribute = 'data-airport-picker-disable-after-unchecked';

function init() {
	if (!$('.js-airport-picker-trigger').length) {
		return;
	}
	attachEvents();

	$('.js-selector-airport-wrapper').each(function() {
		var currentAirportPicker = $(this);
		checkIfCheckboxGroupShouldBePopulated(currentAirportPicker);
		setSelectedText(currentAirportPicker);
	});

}

function attachEvents() {

	window.site.body.on('click keypress', '.js-airport-picker-trigger', function(e) {
		if (e.type === 'click' || ((e.type === 'keypress') && e.which === 13)) {
			if (!pickerIsOpen()) {
				if (!$(this).find('.js-airport-picker-facade').is(':disabled')) {
					openAirportPicker($(this));
					getCurrentPicker().find('.js-airport-picker-checkbox').not(':disabled').first().focus();
				}
			} else {
				closeAirportPicker(true, true);
			}
			e.preventDefault();
		}
	});

	window.site.body.on('click', function(e) {

		if (!pickerIsOpen()) {
			return;
		}

		if ($(e.target).hasClass('js-dynamic-search-when')
			|| $(e.target).hasClass('js-datepicker-wrapper')
			|| $(e.target).hasClass('js-folks-selector-departing')
			|| $(e.target).hasClass('js-folks-selector-trigger')
			|| $(e.target).hasClass('js-destination-picker-facade')
			|| $(e.target).hasClass('js-destination-picker-trigger')) {
			closeAirportPicker(false, true);
			return;
		}

		// If not a click on the airport picker
		if (!$(e.target).parents('.js-airport-picker').length
			&& !$(e.target).closest('.js-airport-picker').length
			&& !$(e.target).parents('.m-search-block__form__row--airport').length
			&& !$(e.target).hasClass('js-airport-picker-trigger')
			&& !$(e.target).hasClass('js-airport-picker-button')
			&& !$(e.target).hasClass('js-airport-picker-back')
			&& !$(e.target).hasClass('js-dynamic-search-when')
			&& !$(e.target).hasClass('m-search-block__form__row--airport')) {

			if ($('.js-airport-picker').hasClass(activeClass)) {
				closeAirportPicker(true, true);
			}

		}

	});


	window.site.body.on('click', '.js-airport-picker-apply, .js-airport-picker-close', function(e) {

		closeAirportPicker(true, true);

	});

	window.site.body.on('change', '.js-airport-picker-checkbox', function(e) {
		setOtherCheckboxStateInGroup($(this));
		setSelectedText(getCurrentPicker());
		setHiddenInput(getCurrentPicker());
	});

	window.site.doc.on("searchOptionsReceived", function(data) {
		if (data.options.EnableDepartureAirports) {
			enableAirportPicker(data.wrapper);
		} else {
			disableAirportPicker(data.wrapper);
		}
		setActiveAirports(data);
		setHiddenInput($(data.wrapper));
	});

}

function enableAirportPicker(wrapper) {
	$(wrapper).find('.js-airport-picker-facade').removeClass('js-disabled-input').prop('disabled', false);
}

function disableAirportPicker(wrapper) {

	$(wrapper).find('.js-airport-picker-facade').addClass('js-disabled-input').prop('disabled', true);

	// reset the checkboxes and selected text
	$(wrapper).find('.js-airport-picker-checkbox').each(function() {
		$(this).prop('checked', false);
		disableCheckbox($(this));
	});
	setSelectedText($(wrapper));
	setHiddenInput($(wrapper));
}

function setActiveAirports(data) {
	$(data.wrapper).find('.js-airport-picker-checkbox').not('.js-airport-picker-checkbox-group').each(function() {
		var currentCheckbox = $(this);
		var inputValue = currentCheckbox.attr('data-airport-picker-iata');
		currentCheckbox.removeAttr(disabledAfterUncheckedAttribute);
		if (data.options.DepartureAirports.indexOf(inputValue) !== -1) {
			enableCheckbox(currentCheckbox);
		} else {
			if (currentCheckbox.is(':checked')) {
				currentCheckbox.attr(disabledAfterUncheckedAttribute, 'true');
			} else {
				disableCheckbox(currentCheckbox);
			}
		}
		setOtherCheckboxStateInGroup(currentCheckbox);

	});
}

function disableCheckbox(checkbox) {
	checkbox.addClass('js-disabled-input').prop('disabled', true);
	checkbox.closest('.c-airport-picker__list-item').addClass('c-airport-picker__list-item--disabled');
}

function enableCheckbox(checkbox) {
	checkbox.removeClass('js-disabled-input').prop('disabled', false);
	checkbox.closest('.c-airport-picker__list-item').removeClass('c-airport-picker__list-item--disabled');
}

function getCurrentPicker() {
	return $('.js-airport-picker:visible').closest('.js-selector-airport-wrapper');
}

function pickerIsOpen() {
	return getCurrentPicker().find('.js-airport-picker').hasClass(activeClass);
}

function openAirportPicker(trigger) {
	closeAirportPicker(false, false);
	trigger.closest('.js-selector-airport-wrapper').find('.js-airport-picker').addClass('is-active');
	trigger.closest('.js-selector-airport-wrapper').find('.js-airport-picker-facade-container').addClass('is-active');
	// set scroll
	if (window.site.win.width() < breakpoints.tablet) {

		lockout.on()
		$('.l-nav').css('z-index', 10);
	}
	window.site.html.addClass('airport-picker-open');
	searchBlockPickerOpen(trigger);

	$('.js-airport-picker-apply').removeAttr('disabled');
	trigger.closest('.js-selector-airport-wrapper').attr('data-desination-initial', $('.js-folks-selector-wrapper:visible').find('.js-folks-selector-destination').val());
}

function closeAirportPicker(triggerSearch, triggerUpdateSearchBarOptions) {

	// if we're on the search results page
	if ($('.js-search-results-page').length > 0) {
		updatetriggerSearchAfterUpdateValue(triggerSearch)
	}

	if (triggerUpdateSearchBarOptions) {
		updateSearchBarOptions();
	}


	if (triggerSearch) {
		if (getCurrentPicker().closest('form').hasClass('js-dynamic-search')
			&& !$('.js-airport-picker-no-auto-submit').length
		) {
			dynamicSearchSubmit(false);
		}
	}
	// set scroll
	window.site.html.removeClass('airport-picker-open');
	searchBlockPickerClose();

	if (window.site.win.width() < breakpoints.tablet) {
		lockout.off()
		$('.l-nav').css('z-index', 12);
	}
	$('.js-airport-picker').removeClass(activeClass);
	$('.js-airport-picker-facade-container').removeClass('is-active');
}

function setSelectedText(pickerToSet) {

	var checkedCheckboxes = pickerToSet.find('.js-airport-picker-checkbox:checked').not('.js-airport-picker-checkbox-group');

	if (!checkedCheckboxes.length) {
		var noneSelectedText = pickerToSet.find('.js-airport-picker-facade').attr('data-airport-picker-none-selected-text');
		pickerToSet.find('.js-airport-picker-facade').val(noneSelectedText);
		return;
	}

	var airportText = checkedCheckboxes.first().attr('data-airport-picker-name');
	if (checkedCheckboxes.length > 1) {
		airportText += ' + ' + (checkedCheckboxes.length - 1).toString();
	}

	pickerToSet.find('.js-airport-picker-facade').val(airportText);

}

function setHiddenInput(pickerToSet) {

	var checkedCheckboxes = pickerToSet.find('.js-airport-picker-checkbox:checked').not('.js-airport-picker-checkbox-group');

	var airportIds = [];
	checkedCheckboxes.each(function(index, element) {
		airportIds.push(element.getAttribute('data-airport-picker-iata'));
	});

	pickerToSet.find('.js-airport-picker-input').val(airportIds.join('|'));

}

function setOtherCheckboxStateInGroup(checkboxChanged) {

	var isMasterCheckbox = checkboxChanged.hasClass('js-airport-picker-checkbox-group');
	var checkboxGroupContainer = checkboxChanged.closest('.js-airport-picker-group');

	if (isMasterCheckbox) {

		var isMasterCheckboxChecked = checkboxChanged.is(':checked');
		checkboxGroupContainer.find('.js-airport-picker-checkbox').not(':disabled').prop('checked', isMasterCheckboxChecked);

		return;

	} else { // your average checkbox Joe

		if (checkboxChanged.is(':checked')) {

			// if all of the other checkboxes are checked tick the group
			shouldCheckboxGroupCheckboxBeChecked(checkboxGroupContainer);

		} else {
			checkboxGroupContainer.find('.js-airport-picker-checkbox-group').prop('checked', false);

			if (checkboxChanged.attr(disabledAfterUncheckedAttribute)) {
				disableCheckbox(checkboxChanged);
				checkboxChanged.removeAttr(disabledAfterUncheckedAttribute);
			}
		}

		shouldCheckboxGroupCheckboxBeDisabled(checkboxGroupContainer);

		return;
	}
}

function shouldCheckboxGroupCheckboxBeChecked(checkboxGroupContainer) {

	var someCheckboxesNotChecked = false;
	checkboxGroupContainer.find('.js-airport-picker-checkbox').not('.js-airport-picker-checkbox-group').each(function(index, element) {
		if (!$(element).is(':checked')) {
			someCheckboxesNotChecked = true;
			return false;
		}
	});

	checkboxGroupContainer.find('.js-airport-picker-checkbox-group').prop('checked', !someCheckboxesNotChecked);

}

function shouldCheckboxGroupCheckboxBeDisabled(checkboxGroupContainer) {

	var someCheckboxesNotDisabled = false;
	checkboxGroupContainer.find('.js-airport-picker-checkbox').not('.js-airport-picker-checkbox-group').each(function(index, element) {
		if (!$(element).is(':disabled')) {
			someCheckboxesNotDisabled = true;
			return false;
		}
	});

	if (someCheckboxesNotDisabled) {
		enableCheckbox(checkboxGroupContainer.find('.js-airport-picker-checkbox-group'));
	} else {
		disableCheckbox(checkboxGroupContainer.find('.js-airport-picker-checkbox-group'));
	}

}

function checkIfCheckboxGroupShouldBePopulated(currentPicker) {
	currentPicker.find('.js-airport-picker-group').each(function() {
		shouldCheckboxGroupCheckboxBeChecked($(this));
	});
}

function reInitAfterSearch() {

	var searchBlockAirportPicker = $('.js-search-results-page .js-selector-airport-wrapper:visible');

	// re-assign airport picker text
	checkIfCheckboxGroupShouldBePopulated(searchBlockAirportPicker);
	setSelectedText(searchBlockAirportPicker);

}



export default init;
export { closeAirportPicker as close, reInitAfterSearch }


